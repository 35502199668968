import React, { useState } from "react";
import Zmage from "react-zmage";
import Fade from "react-reveal";
import 'react-zmage/lib/zmage.css';


let id=0;
const Project = (props)=>{
  const project = props.project;
  return(
    <div key={id++} className="columns portfolio-item">
      <div className="item-wrap">
        <Zmage alt={project.title} src={"/images/portfolio/" + project.image} />
        <div style={{ textAlign: "center" }}>{project.title}</div>
      </div>
    </div>
  )
}

function Portfolio(props) {

    const projectsInfo = [
      {name: "Alistithmar Capital",category:"Alistithmar", images:[
        "istithmar/a1.jpg",
        "istithmar/a10.jpg",
        "istithmar/a11.jpg",
        "istithmar/a2.jpg",
        "istithmar/a3.jpg",
        "istithmar/a4.jpg",
        "istithmar/a5.jpg",
        "istithmar/a6.jpg",
        "istithmar/a7.jpg",
        "istithmar/a8.jpg",
        "istithmar/a9.jpg"
      ]},
      {name: "Nupco",category:"Nupco", images:[
        "nupco/b1.jpg",
        "nupco/b10.jpg",
        "nupco/b11.jpg",
        "nupco/b2.jpg",
        "nupco/b3.jpg",
        "nupco/b4.jpg",
        "nupco/b5.jpg",
        "nupco/b6.jpg",
        "nupco/b7.jpg",
        "nupco/b8.jpg",
        "nupco/b9.jpg"
      ]
      },
      {name:"MDIS", category:"MDIS", images:[
        "mdis/IMG_2957.jpg",
        "mdis/IMG_2961.jpg",
        "mdis/IMG_2969.jpg",
        "mdis/IMG_2975.jpg",
        "mdis/IMG_2986.jpg",
        "mdis/IMG_2988.jpg",
        "mdis/IMG_3006.jpg",
        "mdis/IMG_3012.jpg",
        "mdis/IMG_3028.jpg",
        "mdis/IMG_3029.jpg",
        "mdis/IMG_3031.jpg",
        "mdis/IMG_3035.jpg",
        "mdis/IMG_3038.jpg",
        "mdis/IMG_3041.jpg",
        "mdis/IMG_3087.jpg",
        "mdis/IMG_3094.jpg",
        "mdis/IMG_3100.jpg"
      ]      
      },
      {name:"National Day", category:"ND", images:[
        "nd/IMG_7293.jpg",
        "nd/IMG_7311.jpg",
        "nd/IMG_7317.jpg",
        "nd/IMG_7322.jpg",
        "nd/IMG_7324.jpg",
        "nd/IMG_7328.jpg",
        "nd/IMG_7360.jpg",
        "nd/IMG_7380.jpg",
        "nd/IMG_7444.jpg",
        "nd/IMG_7450.jpg",
        "nd/IMG_7457.jpg",
        "nd/IMG_7459.jpg",
        "nd/IMG_7461.jpg",
        "nd/IMG_7464.jpg"
      ]      
      },
      {
        name:"NTP Kids Day", category:"NTP", images:[
          "ntp/0D7A29E3-BE07-4034-A48F-710AD5DC3214.JPG",
          "ntp/5CA85D8A-F5DE-41BE-B726-95A1CE55CF67.JPG",
          "ntp/8E60E489-596B-45E2-BC44-23C5FE3777AE.JPG",
          "ntp/A1B88A37-7635-4D64-8E77-BABBDE41F865.JPG",
          "ntp/BE3D9358-2247-4918-A9FE-2E04E2160DB9.JPG",
          "ntp/ED8DE57D-80D0-4F68-B9C4-B2277F9A8049.JPG",
          "ntp/F6434946-69A1-400E-8D04-B0F523A7A037.JPG",
          "ntp/FDAD7A61-6963-4227-B97D-97B8A5973EBB.JPG",
          "ntp/IMG_8283.jpg",
          "ntp/IMG_8286.jpg",
          "ntp/IMG_8287.jpg",
          "ntp/IMG_8289.jpg",
          "ntp/IMG_8495.jpg",
          "ntp/IMG_8496.jpg",
          "ntp/IMG_8497.jpg",
          "ntp/IMG_8498.jpg",
          "ntp/IMG_8499.jpg",
          "ntp/IMG_8500.jpg",
          "ntp/IMG_8501.jpg",
          "ntp/IMG_8504.jpg",
          "ntp/IMG_8508.jpg",
          "ntp/IMG_8512.jpg",
          "ntp/IMG_8515.jpg",
          "ntp/IMG_8516.jpg",
          "ntp/IMG_8517.jpg",
          "ntp/IMG_8518.jpg"
        ]        
      },
      {
        name:"Diriyah", category:"Diriyah",
        images:[
          "diriyah/IMG_6602.jpg",
          "diriyah/IMG_6603.jpg",
          "diriyah/IMG_6606.jpg",
          "diriyah/IMG_6607.jpg",
          "diriyah/IMG_6608.jpg",
          "diriyah/IMG_6611.jpg",
          "diriyah/IMG_6612.jpg",
          "diriyah/IMG_6615.jpg",
          "diriyah/IMG_6616.jpg",
          "diriyah/IMG_6618.jpg",
          "diriyah/IMG_6619.jpg",
          "diriyah/IMG_6620.jpg",
          "diriyah/IMG_6625.jpg",
          "diriyah/IMG_6628.jpg",
          "diriyah/IMG_6629.jpg",
          "diriyah/IMG_6632.jpg",
          "diriyah/IMG_6639.jpg",
          "diriyah/IMG_6642.jpg"
        ]
        
      }
    ]

    let iprojects = [];
    
    projectsInfo.map((info)=>{
      info.images.map((img)=>{
        iprojects.push({
          "title": info.name,
          "category": info.category,
          "image": img
        })
      })
    })
    const [projects, setProjects] = useState(iprojects);
    const [filter, setFilter ] = useState(projectsInfo[0].category);

    if(!props.data){
      return null;
    }    
    const projectsTitle = props.data.title;


    return (
      
      <section id="portfolio">
        {/* <Fade left duration={1000} distance="40px"> */}
          <div className="row">
            <div /* className="twelve columns collapsed" */>
              <h2>{projectsTitle}</h2>
              {projectsInfo.map((obj,key)=>(
                <button 
                  className={filter===obj.category?'portfolio-link-selected':'portfolio-link'} 
                  onClick={()=>setFilter(obj.category)}>{obj.category}</button>
              ))}
               
              <div
                id="portfolio-wrapper"
                className="bgrid-quarters s-bgrid-thirds cf"
              >
                {projects?.filter((p)=>p.category===filter).map((obj, key)=>(
                  <Project project={obj} key={key} />
                ))}
              </div>
            </div>
          </div>
        {/* </Fade> */}
      </section>
    );

}

export default Portfolio;