import React, { Component } from "react";
import Fade from "react-reveal";
import Zmage from "react-zmage";

import 'react-zmage/lib/zmage.css'
class Why extends Component {
  render() {
    if (!this.props.data) return null;

    const profilepic = "images/doomidoo-logo@1x.png";
    const whypic1 = "images/doomidoo/1.JPG";
    const whypic2 = "images/doomidoo/2.JPG";
    const whypic3 = "images/doomidoo/3.JPG";
    const whypic4 = "images/doomidoo/4.JPG";
    const whypic5 = "images/doomidoo/5.JPG";
    const whypic6 = "images/doomidoo/6.JPG";
    const whypic7 = "images/doomidoo/7.JPG";
    const whypic8 = "images/doomidoo/8.JPG";
    const whyTitle = this.props.data.title;
    const points = this.props.data.points;
    const specTitle = this.props.data.spectrum.title;
    const col1Items = this.props.data.spectrum.col1Items;
    const col2Items = this.props.data.spectrum.col2Items;
    const culture = this.props.data.culture;
    const values = this.props.data.values;

    return (
      <section id="why">

          <div className="row">
            <div className="three columns">
              
              <Zmage

              src={whypic1}
              alt="DD Marasem"
            />
            <Zmage

              src={whypic2}
              alt="DD Marasem"
            />
            <Zmage

                src={whypic3}
                alt="DD Marasem"
              />
              <Zmage

              src={whypic4}
              alt="DD Marasem"
              />
              <Zmage

                src={whypic5}
                alt="DD Marasem"
              />
              <Zmage

              src={whypic6}
              alt="DD Marasem"
              />
              <Zmage

                src={whypic7}
                alt="DD Marasem"
              />
              <Zmage

                src={whypic8}
                alt="DD Marasem"
                />

            </div>
            <div className="nine columns">
              {/* why heading */}
              <h2 style={{marginBottom: 40}}>{whyTitle}</h2>
              {points.map((obj,key) => 
                <div key={key} style={{marginTop: 20}}>
                    <h3>{obj.title}</h3>
                    <p>{obj.p}</p>
                </div>
              )};

              <h2 style={{marginBottom: 30}}>{specTitle}</h2>
                
               {/*  <table>
                  <tbody>
                    <tr>
                      <td> */}
                      <div className="row">
                        <div className="six columns">
                          <ul class="special">
                            {col1Items.map((obj,key)=>
                              <li key={key}>{obj}</li>
                            )};
                          </ul>
                        </div>
                        <div className="six columns">
                          <ul class="special">
                            {col2Items.map((obj,key)=>
                                <li key={key}>{obj}</li>
                            )};
                          </ul>
                        </div>
                      </div>
                     
                     {/*  </td>
                      <td> */}
                      
                      {/* </td>
                    </tr>
                  </tbody>
                </table> */}

              <h2 style={{marginBottom: 40, marginTop: 30}}>{culture.title}</h2>
              
              {culture.points.map((obj,key) => 
                <div key={key} style={{marginTop: 20}}>
                    <h3>{obj.title}</h3>
                    <p>{obj.p}</p>
                </div>
              )};
           
              <h2 style={{marginBottom: 40, marginTop: 30}}>{values.title}</h2>

              {values.points.map((obj,key) => 
                <div key={key} style={{marginTop: 20}}>
                    <h3>{obj.title}</h3>
                    <p>{obj.p}</p>
                </div>
              )};

             
            </div>
          </div>

      </section>
    );
  }
}
export default Why;
        {/* <Fade duration={1000}> */}
                {/* </Fade> */}
  {/* <li>
                  Corporate meetings
                  </li>
                  <li>
                  National holidays and seasonal events
                  </li>
                  <li>
                  Team-building activities
                  </li>
                  <li>
                  Workshops and training sessions
                  </li>
                  <li>
                  Conferences and seminars
                  </li>
                  <li>
                  Awards and recognition ceremonies
                  </li>
                  <li>
                  Trade exhibitions
                  </li> */}
                  
  {/* <li>
                Community charity events
                </li>
                <li>
                Anniversary celebrations
                </li>
                <li>
                Marketing and sales events
                </li>
                <li>
                Job fairs and recruitment exhibitions
                </li>
                <li>
                Board meetings
                </li>
                <li>
                Partner events and meetings
                </li> */}
{
                  /* <div className="row">
                    <div className="columns contact-details">
                      <h2>Contact Details</h2>
                      <p className="address">
                        <span>{name}</span>
                        <br />
                        <span>
                          {street}
                          <br />
                          {city} {state}, {zip}
                        </span>
                        <br />
                        <span>{phone}</span>
                        <br />
                        <span>{email}</span>
                      </p>
                    </div>
                    <div className="columns download">
                      <p>
                        <a href={resumeDownload} className="button">
                          <i className="fa fa-download"></i>Download Resume
                        </a>
                      </p>
                    </div>
                  </div> */}

                  {/*  <h3>Experience</h3>
              <p>We manage your event to create a sense of connection and tell your story in a way that resonates with your audience and immerses them in feeling deeply involved. We capture your vision and values to deliver a memorable event that awakens senses and ignites imagination.</p>
              
              <h3>Creativity</h3>
              <p>Our unique approach breaks free from normality and sets our name apart. With a team of creative experts using the latest technologies, we're fully equipped to push boundaries, redefine industry standards, and deliver truly iconic corporate events.</p>
              
              <h3>Trust</h3>
              <p>We foster transparency and confidence in our relations with clients, stakeholders, partners, and suppliers, and communicate accurate information at all times. We listen attentively to all wants and needs, work in dedication, provide sincere consultations, and always deliver on our promises.</p>
              
              <h3>Advancing Toward Saudi Vision 2030</h3>
              <p>Doomidoo strives to create a solid bond between companies, individuals, entrepreneurs, businessmen, investors, and government entities which in turn facilitates a thriving business ecosystem that paves the way for sustainable growth. Therefore, Doomidoo continues to utilize its capabilities, capitalize on the value of this local business unity, and contribute to the true fulfillment of Saudi Vision 2030.</p>
 */}

   {/*  <h3>A mission we relentlessly pursue</h3>
              <p>Crafting exceptional corporate experiences that bring companies closer to their employees, audiences, and partners and inspire business solidarity which contributes to the local economy and the welfare of the Saudi society.</p>
              
              <h3>The vision leading our way to the future</h3>
              <p>To set a new benchmark of events management in Saudi Arabia, add value to local businesses, and empower each company to reach further heights and achieve remarkable outcomes.</p>
               */}