import React, { useEffect, useMemo, useState } from "react";
import ParticlesBg from "particles-bg";
import Fade from "react-reveal";

const Header = (props) => {
    if (!props.data) return null;
    const description = props.data.description;
    const colors = ["#51388e","#69bae9","#c74593","#f8a91e"];

    function switchLang(){
      props.currentLang==="ar"?props.setCurrentLang("en"):props.setCurrentLang("ar");
    }

      return (
        <header id="home">

          <div className="background">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>

          </div>
  
          <nav id="nav-wrap">
            <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
              Show navigation
            </a>
            <a className="mobile-btn" href="#home" title="Hide navigation">
              Hide navigation
            </a>
  
            <ul id="nav" className="nav">
              <li className="current">
                <a className={props.currentLang==="ar"?"smoothscroll arText":"smoothscroll"} href="#home">
                  
                {props.currentLang==="en"?'Home':"الرئيسية"}
                </a>
              </li>
  
              <li>
                <a className={props.currentLang==="ar"?"smoothscroll arText":"smoothscroll"} href="#about">
                {props.currentLang==="en"?'About':"عن دد مراسم"}
                  
                </a>
              </li>
  
              <li>
                <a className={props.currentLang==="ar"?"smoothscroll arText":"smoothscroll"} href="#resume">
                {props.currentLang==="en"?'Services':"خدماتنا"}
                  
                </a>
              </li>

              <li>
                <a className={props.currentLang==="ar"?"smoothscroll arText":"smoothscroll"} href="#why">
                {props.currentLang==="en"?'Why Us':"لماذا نحن"}
                 
                </a>
              </li>
  
              <li>
                <a className={props.currentLang==="ar"?"smoothscroll arText":"smoothscroll"} href="#portfolio">
                {props.currentLang==="en"?'Portfolio':"المشاريع"}
                  
                </a>
              </li>
  
              <li>
                <a className={props.currentLang==="ar"?"smoothscroll arText":"smoothscroll"} href="#contact">
                {props.currentLang==="en"?'Contact':"تواصل"}
                  
                </a>
              </li>

              <li>
                <a className={props.currentLang==="en"?"arText langLink":"langLink"} onClick={()=>switchLang()}>
                  {props.currentLang==="en"?'العربية':"English"}
                </a>
              </li>
            </ul>
          </nav>
  
          <div className="row banner">
            <div className="banner-text">
              <Fade top duration={2500}>
                <img width={350} src="./images/doomidoo-logo@1x.png" />
              </Fade>
              <br></br><br></br>
              <Fade top duration={2500}>
                <img width={100} src="./images/Saudi_Vision_2030_logo.svg.png" />
              </Fade>
              <hr />
            </div>
          </div>
  
          <p className="scrolldown">
            <a className="smoothscroll" href="#about">
              <i className="icon-down-circle"></i>
            </a>
          </p>
        </header>
      );
}
export default Header;