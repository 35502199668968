import React, { Component } from "react";
import Slide from "react-reveal";

class Resume extends Component {
  getRandomColor() {
    let letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  render() {
    if (!this.props.data) return null;

    const services = this.props.data;

    return (
      <section id="resume">
        
        <div className="row work">
          <div className="twelve columns">
          
          {/* <h2>About DD Marasem</h2> */}
          <h2>{services.title}</h2>
          </div>
        </div>

        {services.points.map((obj,key) => 
        <Slide key={key} left duration={1300}>
          <div className="row work">
            <div className="twelve columns">
           
            <h1>
              <span>{obj.title}</span>
            </h1>
            
            <p>{obj.p}</p>
            
            </div>
            
          </div>
        </Slide>
        )}

      </section>
    );
  }
}

export default Resume;


 {/* <div className="row work">
            
              <h1>
                <span>Managing Logistics</span>
              </h1>
              <p>Based on your event's needs, we leverage our vast network of local connections to find the ideal venue and get the best deals for transportation and accommodation. We also negotiate with all parties and manage schedules and timelines to ensure the punctual and flawless execution of each phase of the event.</p>  
           
          </div> */}
           {/*  <div className="three columns header-col">
              <h1>
                <span>{obj.title}</span>
              </h1>
            </div>

            <div className="nine columns main-col">
              <div className="row item">
                <div className="twelve columns">
                  {obj.p}
                </div>
              </div>
            </div> */}
        {/* <Slide left duration={1300}>
          <div className="row skill">
            <div className="three columns header-col">
              <h1>
                <span>Skills</span>
              </h1>
            </div>

            <div className="nine columns main-col">
              <p>{skillmessage}</p>

              <div className="bars">
                <ul className="skills">{skills}</ul>
              </div>
            </div>
          </div>
        </Slide> */}

/* const skillmessage = this.props.data.skillmessage;
const education = this.props.data.education.map(function (education) {
  return (
    <div key={education.school}>
      <h3>{education.school}</h3>
      <p className="info">
        {education.degree} <span>&bull;</span>
        <em className="date">{education.graduated}</em>
      </p>
      <p>{education.description}</p>
    </div>
  );
});

const work = this.props.data.work.map(function (work) {
  return (
    <div key={work.company}>
      <h3>{work.company}</h3>
      <p className="info">
        {work.title}
        <span>&bull;</span> <em className="date">{work.years}</em>
      </p>
      <p>{work.description}</p>
    </div>
  );
});

const skills = this.props.data.skills.map((skills) => {
  const backgroundColor = this.getRandomColor();
  const className = "bar-expand " + skills.name.toLowerCase();
  const width = skills.level;

  return (
    <li key={skills.name}>
      <span style={{ width, backgroundColor }} className={className}></span>
      <em>{skills.name}</em>
    </li>
  );
}); */


{/*         <Slide left duration={1300}>
          <div className="row work">
            <div className="three columns header-col">
              <h1>
                <span>Managing Logistics</span>
              </h1>
            </div>

            <div className="nine columns main-col">
              Based on your event's needs, we leverage our vast network of local connections to find the ideal venue and get the best deals for transportation and accommodation. We also negotiate with all parties and manage schedules and timelines to ensure the punctual and flawless execution of each phase of the event.  
            </div>
          </div>
        </Slide>

        <Slide left duration={1300}>
          <div className="row work">
            <div className="three columns header-col">
              <h1>
                <span>Design & Décor</span>
              </h1>
            </div>

            <div className="nine columns main-col">
            We celebrate creativity within our work and are equipped with cutting-edge technologies to craft astonishing event visual identity, conceptualize logos & mottos, and plan and execute interior designs and décor. In addition, we select colors and furniture to harmonize with the concept design, all while meeting the needs of your audience.
            </div>
          </div>
        </Slide>

       

        <Slide left duration={1300}>
          <div className="row work">
            <div className="three columns header-col">
              <h1>
                <span>Program, Audiovisual, and Live Shows</span>
              </h1>
            </div>

            <div className="nine columns main-col">
              Our expert team is ready to develop special programs tailored to your unique audience. Moreover, our production experts specialize in delivering breathtaking audiovisual effects that transform your event into a fully immersive and sensational experience.
            </div>
          </div>
        </Slide>

        
        
        <Slide left duration={1300}>
          <div className="row work">
            <div className="three columns header-col">
              <h1>
                <span>F&B Catering</span>
              </h1>
            </div>

            <div className="nine columns main-col">
            Your special event deserves delicious and expertly served menus and for that, we have the ultimate catering solution. Doomidoo provides a dedicated catering staff serving a wide range of mouthwatering food and beverage menus, including special arrangements for your VIPs.
            </div>
          </div>
        </Slide>

        <Slide left duration={1300}>
          <div className="row work">
            <div className="three columns header-col">
              <h1>
                <span>Safety & Security</span>
              </h1>
            </div>

            <div className="nine columns main-col">
            We make sure your event is safe and secure by meeting all safety requirements and following local security regulations. Upon request, we are ready to coordinate with third- party specialized security companies based on the size of your event and audience.
            </div>
          </div>
        </Slide>


        <Slide left duration={1300}>
          <div className="row work">
            <div className="three columns header-col">
              <h1>
                <span>Marketing, Promotion, and Coverage</span>
              </h1>
            </div>

            <div className="nine columns main-col">
              Your event isn't a regular gathering, it is an extraordinary occasion that deserves attention and publicity. Doomidoo offers you marketing campaigns and full coverage through social media to raise awareness and achieve higher attendance rates. In addition, we are ready to handle ticket registration and sales across various platforms after obtaining the required governmental approvals.
            </div>
          </div>
        </Slide> */}