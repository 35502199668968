import React, { useState, useEffect } from "react";
import ReactGA from "react-ga";
import $ from "jquery";
import "./App.css";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import About from "./Components/About";
import Resume from "./Components/Resume";
import Contact from "./Components/Contact";
import Portfolio from "./Components/Portfolio";
import Why from "./Components/Why";

function App() {
/* ReactGA.initialize("UA-110570651-1");
    ReactGA.pageview(window.location.pathname); */
  
  const [resumeData, setResumeData] = useState({});
  const [currentLang, setCurrentLang] = useState("en");

  const [defaultCss, setDefaultCss] = useState("default.css");
  const [layoutCss, setLayoutCss] = useState("layout.css");

  useEffect(()=>{
    if(currentLang==="ar"){
      //change document direction
      document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");
      //change to arabic fonts
      document.body.classList.add('arBody');
      getResumeData();
    }else{
      //change document direction
      document.getElementsByTagName('html')[0].setAttribute("dir", "ltr");
      //change to english fonts
      document.body.classList.remove('arBody');
      getResumeData();
    }
  },[currentLang])

  const changeLang = ()=>{
    
   
  }

  useEffect(()=>{
    getResumeData();
      
    },[])

  

  function getResumeData() {
    $.ajax({
      url: currentLang==="ar"?"./resumeDataAr.json":"./resumeData.json",
      dataType: "json",
      cache: false,
      success: function(data) {
       setResumeData(data);
       console.log(data);
      }.bind(this),
      error: function(xhr, status, err) {
        console.log(err);
        alert(err);
      }
    });
  }

    return (
      <div className="App">
        <Header currentLang={currentLang} setCurrentLang={setCurrentLang} data={resumeData.main} />
        <About data={resumeData.main} />
        <Resume data={resumeData.services} />
        <Why data={resumeData.why} />
        <Portfolio data={resumeData.portfolio} />
        <Contact data={resumeData.main} />
        <Footer data={resumeData.main} />
      </div>
    );
}

export default App;
